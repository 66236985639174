.Applications-sec{
    background-color: white;
    padding-bottom: 5rem;
}

.Applications-sec-text{
    padding-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 4rem;
}

.Applications-sec-text h6 {

    font-size: 37px;
    font-weight: 600;
}

.app-card{

width: auto;
height: 150px;
border: 1px solid #C4C4C4 ;
padding: 1rem;
display: flex;
column-gap: 0%;

}

.cards-flex{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2%;
    row-gap: 5%;
}

.app-card h6{
        padding-top: 0.5rem;
        font-size: 20px;
        font-weight: 600;
    }

    .app-card p{ 
        font-size: 15px;
        font-weight: 400;
        color:#626262;
    
    }

    .app-card-right1 {
        width: 150px;
        height: 130px !important;
    }
    .app-card-right1 img{

        width: 150px;
        height: 130px;
    }

    
    @media (max-width:480px)  { 



        .Applications-sec{
            background-color: white;
            padding-bottom: 0rem;
        }
        
        .Applications-sec-text{
            padding-top: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding-bottom: 1rem;
        }
        
        .Applications-sec-text h6 {
        
            font-size: 28px;
            font-weight: 600;
            
        }
        
        .app-card{
        
        width: auto;
        height: auto;
        border: 0px solid #C4C4C4 ;
        padding: 1rem;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 1rem 2rem;
        
        }
        
        .cards-flex{
            display: flex;
            flex-direction: column;
            column-gap: 0%;
            row-gap: 1rem;
        }
        
        .app-card h6{
                padding-top: 0.5rem;
                font-size: 20px;
                font-weight: 600;
                padding-bottom: 1rem;
            }
        
            .app-card p{ 
                font-size: 12px;
                font-weight: 400;
                color:#626262;
            
            }




    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
    

        .cards-flex{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 1rem;
        }

        .Applications-sec{
            background-color: white;
            padding-bottom: 2rem;
        }
           
        .Applications-sec-text{
            padding-top: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding-bottom: 2rem;
        }
    }