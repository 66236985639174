.pro-sidebar {

background-color: red !important;
background: white !important;
color:white !important;

}
.carwashbtn{

    background: transparent;
    border: 0px;
    color: white;
    font-weight: 700;
    font-size: 18px;
}