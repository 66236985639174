.Featuresweb {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.all-content {

    padding-top: 3rem;

}
.Plan-selection h6{

    font-size: 30px;
}
.plan-container {

    background-image: url('/webapps/carwash_landing/src/Home/WebAssets/Rectangle 158.png');
    padding: 1rem 1.5rem;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 98%;
    border-radius: 10px;
    margin-bottom: 1.5rem;
    /* height: 193px; */
}

.rent-text {

    color: white;
    font-size: 20px;
    font-weight: 400;
    padding-left: 15px;
    width: 300px;

}

.rent-span-pull-right {

    float: right;
    font-size: 20px;
    color: white;
    font-weight: 600;
}

input[type="radio"] {
    width: 17px;
    /* Set the desired width */
    height: 17px;
    /* Set the desired height */
    accent-color: #E54949;

}

.Featuresweb h5 {

    color: #000000;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0px;

}


.Featuresmob {

    display: none;
}


.right-left-app-card {

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    padding-bottom: 3rem;
    margin-top: -2rem;
}

.app-card2 {

    width: 100%;
    height: 197px;
    border: 1px solid #C4C4C4;
    padding: 0.8rem;
    display: flex;

}

.app-card2 h6 {
    padding-top: 0.5rem;
    font-size: 20px;
    font-weight: 600;
}

.app-card2 p {
    font-size: 13px;
    font-weight: 400;
    color: #626262;

}

.row {
    --bs-gutter-x: 0px;

}



@media (max-width:480px) {

    .Plan-selection h6 {

        font-size: 20px;
        font-weight: 600;
        text-align: center;
    }

    .all-content {

        padding-top: 1rem;


    }

    .rent-text {

        color: white;
        font-size: 14px;
        font-weight: 400;
        padding-left: 15px;

    }

    .rent-span-pull-right {

        float: right;
        font-size: 14px;
        color: white;
        font-weight: 600;
    }

    .plan-container {

        background-image: url('/webapps/carwash_landing/src/Home/WebAssets/Rectangle 158.png');
        padding: 1rem 1rem;
        border-radius: 10px;
        background-repeat: no-repeat;
        width: 97%;
        border-radius: 10px;
    }

    .Plan-selection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: start;
    }

    input[type="radio"] {
        width: 17px;
        /* Set the desired width */
        height: 17px;
        /* Set the desired height */
        accent-color: #E54949;

    }


    .right-left-app-card {
        display: flex;
        flex-direction: column;
        row-gap: 0.2rem;
        order: 3;
        padding-bottom: 0rem;
        margin-top: 0rem;


    }

    .Featuresmob {

        display: flex;
        flex-direction: column;
    }

    .Featuresmob h5 {

        color: #000000;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 12px;
        align-items: center;
        text-align: center;
        padding-top: 1rem;


    }

    .app-card2 {

        width: auto;
        height: auto;
        border: 0px solid #C4C4C4;
        padding: 0.8rem;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 10px;

    }

    .rounded {
        border-radius: var(--bs-border-radius) !important;
    }

    .bg-body {
        --bs-bg-opacity: 1;
        background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
    }

    .shadow {
        box-shadow: none !important;
    }

    .Featuresweb {
        display: none;


    }

    .Featuresmob {

        display: block;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {


    .Plan-selection h6 {

        font-size: 26px;
        font-weight: 600;
    }

    .plan-container {

        background-image: url('/webapps/carwash_landing/src/Home/WebAssets/Rectangle 158.png');
        padding: 1rem 1rem;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px;
    }
    
    .right-left-app-card {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        padding-bottom: 0rem;
        margin-top: 0rem;
        padding-top: 1rem;
        padding-bottom: 1rem;

    }

    .Featuresweb {
        display: none;


    }

    .Featuresmob {

        display: block;
    }
    .Featuresmob h5 {

        color: #000000;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 12px;
        align-items: center;

    }

    .app-card2 {

        width: auto;
        height: auto;
        border: 1px solid #C4C4C4;
        padding: 0.8rem;
        

    }
    .Featuresmob {

        display: flex;
        flex-direction: column;
        row-gap: 1rem;
    }

}