.wrapper{

    display: flex;
    flex-direction: row;
}
.Left-Side{

    width: 70%;
    background-image: url('/webapps/carwash_landing/src/assets/Rectangle 156.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
}

.carwashlogo{
    width: 50%;
}

.img-container{
    display: flex !important;
    justify-content: center;
    align-items: center !important;
}

.Left-Side h5{

font-size: 38px;
color: white;
padding-top: 2rem;
align-items: start !important;
text-align: start !important;
justify-content: start !important;
}

.Left-Side p{

    font-size: 20px;
    color: white;
    padding-top: 2rem;
}


.right-Side{
    height: 100vh !important;
    width: 70%;
    background-color:#E6E6E6 ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.right-Side h4{


    font-size: 38px;
}

.titleform{

    padding-bottom:5rem;

}

#exampleInputPassword20{

width: 627px;
height: 60px;
border-radius: 10px;
border: 1px;
font-size: 16px;

}

#exampleInputEmail120{

    width: 627px;
    height: 60px;
    border-radius: 10px;
    border: 1px;
    font-size: 16px;
    
    }

    #labelfont{
    font-size: 18px;
}

#loginformbtn{

    background-color: #1AACE3;
    width: 627px;
    height: 60px;
    border-radius: 10px;
    border: 0px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    margin-top: 1rem;
}

