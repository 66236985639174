.Read-header{
    color:black ;
    font-size: 34px;
    font-weight: 700;
    padding-bottom: 1.5rem;
}

.car-header{
    color:black ;
    font-size: 34px;
    font-weight: 700;
    padding-bottom: 1rem;
    padding-top: 1.5rem;

}

.holder{
    
    font-size: 18px;
    color: black;
    font-weight: 700;
    
}

.values {

font-size: 16px;

font-weight: 400;

}

.grey-container{
   
    background-color: #D9D9D9;
    padding: 4rem;
    border-radius: 8px;

}