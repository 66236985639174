.registeration-Form {
  border: 3px solid #D9D9D9DE;
  border-radius: 5px;
  width: 100%;
  margin-left: 0%;
  height: 760px;
}

.Reg-form {

  padding: 1rem;
  padding-bottom: 0.3;

}

.Reg-form h6 {

  text-align: center;
  font-size: 30px;
  font-weight: 600;
}

.form-control:focus {
  border-color: #D9D9D9DE;
  box-shadow: none;
}


.form-control {
  width: 100%;
  height: 36px !important;
  /* Add other styles as needed */
}

.Register-btn-sec button {
  background-image: url('/webapps/carwash_landing/src/Home/WebAssets/Rectangle 158.png');
  background-size: cover;
  background-repeat: no-repeat;
  border: 0px;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  color: white;
  font-size: 20px;
  margin-top: 0.5rem;
}

.react-tel-input {
  width: 502px !important; /* Adjust the width as needed */
}


.flexing-input{

}

@media (max-width:480px) {

.flexing-input{

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
}
  .registeration-Form {
    border: 3px solid #D9D9D9DE;
    border-radius: 5px;
    width: 100%;
    clear: both;
    margin-right: auto;
    margin-left: auto;
    height: 100%;

  }

  .Register-btn-sec button {
    background-image: url('/webapps/carwash_landing/src/Home/WebAssets/Rectangle 158.png');
    background-size: cover;
    background-repeat: repeat;
    border: 0px;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    margin-top: 1rem;
  }

  .form-control {
    width: 100% !important;
    height: 36px !important;
    /* Add other styles as needed */
  }

  .react-tel-input {
    width: 100% !important; /* Adjust the width as needed */
  }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {


  .registeration-Form {
    border: 3px solid #D9D9D9DE;
    border-radius: 5px;
    width: 85%;
    clear: both;
    margin-right: auto;
    margin-left: auto;
    height: auto;
  }

  .react-tel-input {
    width: 100% !important; /* Adjust the width as needed */
  }

}