.des{

    width: 40%;
}

.add-btns{
float: right;
margin-top: -20px;
display: flex;
column-gap: 5%;

}

.add-btn-back{
    border-radius: 8px;
    color: white !important;
    height: 38px;
    width: 150px;
    border: 0px !important;
    background: black !important;

}

.add-btn-submit{
    color: white;
    height: 38px;
    width: 150px;
    border-radius: 8px;
    background: #E5494999 !important;
    border: 0px !important;
}

.wrapper-service{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px;
    background-color: transparent !important;
    padding: 1rem;
}

.card-icon-service{
    width: 40%;
  
}

.card{
    width: 255px !important;
    height: 200px !important;
    border: 0px !important;
    border-radius: 10px !important;

}

.card-title{


    font-size: 18px;
    font-weight: 700;
    text-align: center;
}

.form-control{


    /* border-color:#e57d49  ; */
}

.Dsc-sec{
    display: flex;
    flex-direction: column !important;
}
 #textarea1{

    height: auto !important;
} 

#inputPassword30{

    width: 45.8% !important;
    height: 50px;
}

.top-btn{
    display: flex;
    float: right;

}

.btn-edit-service{

    border: 1px solid #1AACE3;
    border-radius: 9px;
    width: 170px;
    height: 52px;
    color:#1AACE3;
    background-color: white;
    font-size: 18px;
    font-weight: 600;

}

.go-back{
    margin-left: 10%;
    border: 0px ;
    border-radius: 9px;
    width: 170px;
    height: 52px;
    color:white;
    font-weight: 600;
    background-color: #1AACE3;
    font-size: 18px;

}

.menu-horiz ul {
    list-style-type: none;
    padding: 0;
  }
  
  .menu-horiz li {
    cursor: pointer;
    padding: 10px;
    background: #f0f0f0;
    margin: 5px 0;
    border: 1px solid #ccc;
  }
  
  .menu-horiz li:hover {
    background: #e0e0e0;
  }
  
  .menu-horiz {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
  }
  .menu-list ul {

    list-style-type: none;
    display: flex;
    background-color: #D9D9D9;
    width: 100%;
    height:50px;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  
  .menu-list ul li {
  
    font-size: 18px;
    color: black;
    padding-top: 10px;
    cursor: pointer;
    width: 100%;
    text-align: center;  
  }
  
  .menu-list {
  
    width: auto !important;
    display: flex;
    justify-content: flex-start !important;
    margin-top: 7rem !important;
  
  }
  
  
  
  
  .menu-list ul li a {
  
  
    text-decoration: none;
    color: black;
  
  
  
  }
  
  .all {
  
  
    color: #00AAEF;
    width: 300px;
    height: 50px;
    margin-top: -30px;
    border: 0px;
    border-radius: 4px;
    font-weight: 600;
    background-color: transparent;
  
  }
  
  .List-item3.active {
    background-color: #d2dce1;
    font-weight: 600;
    color: #00AAEF;
    border-radius: 4px;
    cursor: pointer;
  }


  .cards-layout{

    
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    row-gap: 15%;
}

.labelfont{

  font-size: 16px;
  font-weight: 600 !important;
  color: black;
}

.borderless-input {
  border: 1px solid #e1e1da  ;
  outline: none;
  border-radius: 5px;
  width: auto;
}

.updateinputs{

border: 1px solid #DCDCDC		;
border-radius: 8px;
padding-left:4px;


}

.textarea-autosize {
  width: 45.8%;
  padding: 1rem;
  overflow: hidden; /* Hides the scrollbar */
  box-sizing: border-box; /* Ensures padding is included in the width */
}

@media (max-width: 1450px) {
  /* Your styles here */
  .card{
    width: 200px !important;
    height: 200px !important;
    border: 0px !important;
    border-radius: 10px !important;

}
}
