.user-info {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .user-info:before,
  .user-info:after {
    content: " ";
    display: table;
  }
  .user-info:after {
    clear: both;
  }
  .user-info > li {
    float: left;
    margin-right: 2.5px;
  }
  .user-info > li.profile-info {
    margin-right: 10px;
  }
  .profile-info.dropdown > a {
    color: #2b303a;
    text-decoration: none;
    font-size: 14px;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .profile-info.dropdown > a:hover {
    color: #818da2;
  }
  .profile-info.dropdown > a img {
    border: 2px solid #f5f5f5;
    margin-right: 5px;
  }
  .profile-info.dropdown .dropdown-menu {
    background-color: #303641;
    border-color: #454a54;
    padding: 0;
    margin-top: 15px;
  }
  .profile-info.dropdown .dropdown-menu > .caret {
    position: absolute;
    left: 20px;
    top: -4px;
    border-bottom-width: 4px;
    border-top-width: 0;
    border-bottom-color: #454a54;
    margin-left: -2px;
  }
  .profile-info.dropdown .dropdown-menu li {
    color: #aaabae;
    font-size: 12px;
    padding: 0;
  }
  .profile-info.dropdown .dropdown-menu li a {
    color: #aaabae;
    padding: 8px 10px;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .profile-info.dropdown .dropdown-menu li a i {
    margin-right: 5px;
    zoom: 1;
    filter: alpha(opacity=70);
    -webkit-opacity: 0.7;
    -moz-opacity: 0.7;
    opacity: 0.7;
  }
  .profile-info.dropdown .dropdown-menu li a:hover {
    background-color: #2b303a;
    color: #fff;
  }
  .profile-info.dropdown .dropdown-menu li a:hover i {
    zoom: 1;
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  .profile-info.dropdown .dropdown-menu > li {
    border-bottom: 1px solid #454a54;
  }
  .profile-info.dropdown .dropdown-menu > li > a {
    display: block;
    min-width: 210px;
  }
  .profile-info.dropdown .dropdown-menu > li:last-child {
    border-bottom: 0;
  }
  .profile-info.dropdown.pull-right {
    text-align: right;
  }
  .profile-info.dropdown.pull-right > a img {
    margin-left: 5px;
    margin-right: 0;
  }
  .profile-info.dropdown.pull-right .dropdown-menu {
    background-color: #303641;
    border-color: #454a54;
    padding: 0;
    margin-top: 15px;
    text-align: left;
  }
  .profile-info.dropdown.pull-right .dropdown-menu > .caret {
    left: auto;
    right: 20px;
  }