.footer-wrapper{

    background-image: url('/webapps/carwash_landing/src/Home/WebAssets/Rectangle 2.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
.footer {
    color:white;
    height: auto;
  }


  
  
  .footer-left-text{
  
    font-size: 16px;  
    font-weight: 300;
  
  }
  
  
  .footer-list{
  
  
    margin: 20px;
    margin-top: 48px;
  }
  
  .footer-list-div h6{

    font-size: 16px;
    font-weight: bold;
    color: white;
  }
  .footer-list-div {

    margin-bottom: -2% !important ;
  }
  
  .social-icon{
  
  
    margin-left: 10px;
  } 
  
  .social-section{
  
  
 
  }


 

  .subscibe_btn{
    margin-top: 20px;
  }

  .last-section p{
    font-size: 18px;
    color: white;
    font-weight: bold;

  }

  .last-section{

      margin-top: 2.3rem;
      border-top: 2px solid white;
      border-bottom: 0px !important;

  }

  .upper-sec{

    height: 180px;

  }

  .mobapps{

    display: flex;
    flex-direction: column;
    row-gap: 5px;

  }

  .mobapps img {
width: 114px;
height: 36px;

  }
  .flexing{

    display: flex;
    flex-direction: column;
  }
  

  @media only screen and (max-width: 820px) {

    
    .footer {
      margin-left: 0% !important; 
      margin-right: 0%  !important;
    }

    .social-section{


        float: right; margin-left: -3% !important; margin-top: -1px;
      }
}

@media only screen and (max-width: 480px) {

  .footer {
    color:white;
    height: 500px !important;
    padding-top: 1rem !important;
    clear: both !important;
    margin-left: 3% !important;
    margin-right: 3% !important;
    padding-bottom: 1rem !important;

  }
   
  .footer-list-div h6{

    font-size: 14px;
    font-weight: bold;
    color: white;
  }
  .footer-list-div a{

    font-size: 12px;
    font-weight: bold;
    color: white;
  }
  .footer-left-text{
  
    font-size: 12px;  
  
  }

  .fax span{

    font-size: 12px;
  }

  .footer-list-div p{
    padding-top: 10px;
    font-size: 12px;

  }
  .mobapps img {

    width: 70%;
    height: 70%;
  }

  .last-section p {

    font-size: 16px;

  }

  #paddingexample{


    padding-top: 1.5rem !important;

  }

  #paddingexample2{


    padding-top: 10px !important;

  }
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .footer {
    color:white;
    height: 400px !important;
    padding-top: 1rem !important;
    clear: both !important;
    margin-left: 3% !important;
    margin-right: 3% !important;
    padding-bottom: 1rem !important;

  }
  #paddingexample2{


    padding-top: 10px !important;

  }

}
