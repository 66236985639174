.navbar-nav{

    margin-left: 20%;

}

.navbar{
    height: 70px;

}

.nav-item{


    font-size: 22px;
    margin-left: 3rem;
}

.nav-item a{
    color:white !important ;
}

.navbar-wrapper{

background-image: url('/webapps/carwash_landing/src/Home/WebAssets/Rectangle 3.png');
background-size: cover;
}

.navbar-brand{

    color: white;
    font-size: 22px;
}

.signup-btn{

    border: 0px;
    background-color: #FFFFFF;
    width: 125px;
    height: 40px;
    color: red;
    border-radius: 10px;

}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

@media (max-width:480px) {

    .navbar{
        height: auto ;
    
    }
    
.navbar-nav{

    margin-left: 0%;

}

.nav-item a{

    margin-left: -3rem;
}

.signup-btn{

    border: 0px;
    background-color: #FFFFFF;
    height: 40px;
    color: red;
    border-radius: 10px;
    width: 100%;
    margin-left: -3rem;

}

#signup-holder{

    justify-content: safe;
    align-items: center;
    text-align: center;
    clear: both;    
    width: 100%;


}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .navbar{
        height: auto ;
    
    }
    .navbar-nav{

        margin-left: 0%;
    
    }
    
    .nav-item a{
    
        margin-left: -3rem;
    }
    

    .signup-btn{

        border: 0px;
        background-color: #FFFFFF;
        height: 40px;
        color: red;
        border-radius: 10px;
        width: 100%;
    }
    
    #signup-holder{

        justify-content: safe;
        align-items: safe;
        text-align: start;
        clear: both;    
        width: 100%;
    
    
    }
    
    
}