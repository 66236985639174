.First-Sec {

    padding-top: 3rem;
    display: flex;

}

.First-Sec-text {
    width: 50%;

}

.First-Sec-text h6 {

    font-size: 42px;
    font-weight: 700;
}

.First-Sec-text p {

    font-size: 18px;
    font-weight: 400;
    padding-top: 1rem;
}

.First-Sec-img {

    width: 50%;

}

.First-Sec-img img {

    width: 100%;

}

.Third-Sec {

    padding-top: 5rem;
    display: flex;

}

.Third-Sec-text {
    width: 50%;
    padding-top: 8rem;

}

.Third-Sec-text h6 {

    font-size: 45px;
    font-weight: 600;
}

.Third-Sec-text p {

    font-size: 22px;
    font-weight: 400;
    padding-top: 1rem;
}

.Third-Sec-img {

    width: 50%;

}

@media (max-width:480px) {


    .First-Sec {

        padding-top: 1rem;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        text-align: center;

    }

    .First-Sec-img img {

        width: 100%;
        height: 184px;

    }

    .First-Sec-text {
        width: 100%;

    }

    .First-Sec-img {

        width: 100%;

    }

    .First-Sec-text h6 {

        font-size: 20px;
    }

    .First-Sec-text p {

        font-size: 12px;
        font-weight: 400;
        padding-top: 0rem;
        padding: 0px 1rem;

    }

    .Third-Sec {

        padding-top: 1rem;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        text-align: center;

    }

    .Third-Sec-text {
        width: 100%;
        padding-top: 0rem;

    }

    .Third-Sec-text h6 {

        font-size: 20px;
        font-weight: 600;
    }

    .Third-Sec-text p {

        font-size: 12px;
        font-weight: 400;
        padding-top: 0rem;
        padding: 0px 1rem;
    }

    .Third-Sec-img {

        width: 100%;

    }

    .Third-Sec-img img {

        width: 100%;

    }

    .container {
        --bs-gutter-x: 0px;

    }



}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .First-Sec {

        padding-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

    }

    .First-Sec-img img {

        width: 100%;
        height: auto;

    }

    .First-Sec-text {
        width: 100%;

    }

    .First-Sec-img {

        width: 100%;

    }

    .First-Sec-text h6 {

        font-size: 24px;
    }

    .First-Sec-text p {

        font-size: 14px;
        font-weight: 400;
        padding-top: 0rem;
        padding: 0px 1rem;

    }
    .Third-Sec {

        padding-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

    }

    .Third-Sec-text {
        width: 100%;
        padding-top: 0rem;

    }

    .Third-Sec-text h6 {

        font-size: 24px;
        font-weight: 600;
    }

    .Third-Sec-text p {

        font-size: 14px;
        font-weight: 400;
        padding-top: 0rem;
        padding: 0px 1rem;
    }

    .Third-Sec-img {

        width: 100%;

    }

    .Third-Sec-img img {

        width: 100%;

    }


}