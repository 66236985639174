.business-sec{

    background-color: #FDF0F0;
    padding-bottom: 3rem;
}

.business-sec-center-text{
    padding-top: 3rem;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;

}

.business-sec-center-text h6{

    font-size: 40px;
}

.business-sec-center-text button{
    background-image: url('/webapps/carwash_landing/src/Home/WebAssets/button-bg.png');
    width: 204px;
    height: 38.78px;
    border: 0px;
    justify-content: center;
    align-items: center;
    background-size: cover;
}

.business-sec-grid{

    display: flex;
    flex-direction: row;
    column-gap: 1%;

}

.Plans-card{
    background-color: #FFFFFF;
    border-top: 5px solid #A93636;
    border-bottom: 5px solid #A93636;
    width: 400px;
    height: auto;
    border-radius: 5px;
    border-left: 2px solid #A93636;
    border-right: 2px solid #A93636;
    padding: 0px 30px;
}

.Plans-card-text{

    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 2.5rem;
}

.Plans-card-text h5{

    font-size: 30px;
    font-weight: 600;

}

.Plans-card-text h6 {

    font-size: 30px;
    font-weight: 600;
    color: #E54949B5;

}

.Plans-card-text span {
    font-size: 34px;
    font-weight: 600;
    color:#E54949;
}

.check-mark-card{

    display: flex;
    flex-direction: row;

}

.check-mark-card img {


width: 23.5px;
height: 23.5px;

}

.check-mark-card p {


font-size: 15px;
font-weight: 500;
    
    }

.check-sec button{
    background-image: url('/webapps/carwash_landing/src/Home/WebAssets/Rectangle 158.png');
    width: 341px;
    height: 38.68px;
    border: 0px;
    cursor: pointer;
    margin-top: 1rem;
    border-radius: 10px;
    color: white;
    font-weight: bold;
}

.check-sec{

    padding: 1rem 0px;
}

.business-sec-left{


    width: 70%;
}

.business-sec-left img{


    width: 100%;
}

.pack-dsc{

    font-size: 15px;
    padding-top: 1rem;
    display: flex;
    text-align: start;
}

@media (max-width:480px)  {


    .business-sec{

        background-color: #FDF0F0;
        padding-bottom: 2rem;
    }
    
    .business-sec-center-text{
        padding-top: 2rem;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        flex-direction: column;
    
    }
    
    .business-sec-center-text h6{

        font-size: 28px;
        font-weight: bold;
    }
    
    .business-sec-center-text button{
    
        background-image: url('/webapps/carwash_landing/src/Home/WebAssets/button-bg.png');
        width: 204px;
        height: 38.78px;
        border: 0px;
        justify-content: center;
        align-items: center;
        background-size: cover;
        margin-bottom: 1rem;
    
    }

    .business-sec-grid{

        display: flex;
        flex-direction: column;
        row-gap: 3rem;
        justify-content: center;
        align-items: center;
        text-align: center;
    
    }

    .business-sec-left img{

        width: 80%;
    }

    .Plans-card{
        background-color: #FFFFFF;
        border-top: 5px solid #A93636;
        border-bottom: 5px solid #A93636;
        width: 90%;
        height: auto;
        border-radius: 5px;
        border-left: 2px solid #A93636;
        border-right: 2px solid #A93636;
        padding: 0px 30px;
    }

    .business-sec-right{

        display: flex !important;
        justify-content: center !important;
        align-items: center !important;

    }

    .check-sec button{

        width: 100%;
        height: 38.68px;
        border: 0px;
        cursor: pointer;
        margin-top: 1rem;
        border-radius: 10px;
    }
    
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    

    .business-sec{

        background-color: #FDF0F0;
        padding-bottom: 2rem;
    }
    
    .business-sec-center-text{
        padding-top: 2rem;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        flex-direction: column;
    
    }
    
    .business-sec-center-text h6{
    
        font-size: 30px;
    }
    
    .business-sec-center-text button{
    
        background-image: url('/webapps/carwash_landing/src/Home/WebAssets/button-bg.png');
        width: 204px;
        height: 38.78px;
        border: 0px;
        justify-content: center;
        align-items: center;
        background-size: cover;
    
    }
    
    .business-sec-grid{
    
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        padding-top: 2rem;
    
    }
    
    .Plans-card{
        background-color: #FFFFFF;
        border-top: 5px solid #A93636;
        border-bottom: 5px solid #A93636;
        width:315px;
        height: auto;
        border-radius: 5px;
        border-left: 2px solid #A93636;
        border-right: 2px solid #A93636;
        padding: 0px 30px;
        
    }
    
    .Plans-card-text{
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding-top: 2.5rem;
    }
    
    .Plans-card-text h5{
    
        font-size: 30px;
        font-weight: 600;
    
    }
    
    .Plans-card-text h6 {
    
        font-size: 30px;
        font-weight: 600;
        color: #E54949B5;
    
    }
    
    .Plans-card-text span {
        font-size: 34px;
        font-weight: 600;
        color:#E54949;
    }
    
    .check-mark-card{
    
        display: flex;
        flex-direction: row;
    
    }
    
    .check-mark-card img {
    
    
    width: 23.5px;
    height: 23.5px;
    
    }
    
    .check-mark-card p {
    
    
    font-size: 15px;
    font-weight: 500;
    margin-left: 4%;
        
        }
    
    .check-sec button{
    
        background-image: url('/webapps/carwash_landing/src/Home/WebAssets/Rectangle 158.png');
        width:100%;
        height: 38.68px;
        border: 0px;
        cursor: pointer;
        margin-top: 1rem;
        border-radius: 10px;
        color: white;
        font-weight: bold;
    }
    
    .check-sec{
    
        padding: 2rem 0px;
    }

    .business-sec-left img{

        width: 100%;
        height: 70%;
        object-fit:cover;
    }

    .business-sec-left{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
   
}