.form-layout{

display: flex;
justify-content: center;
align-items: center;
border: 1px solid firebrick;
padding: 10rem;

}
.submit-mail{

  background-color: #E54949;
  color: white;
  border: 0px;
  width: 180px;
  height: 35px;
  border-radius: 10px;



}

.pulllll-right{


  display: flex !important;
  float: right !important;
}


#updateforminput10{


  height: 100px !important;
}