.updateuser{

    padding-top: 2rem;
    padding-bottom: 3rem;
    font-weight: 700;
}

#updateforminput{

    height: 50px;
    border-radius: 8px;
    color: black;
    font-size: 16px;

}
.updatepagebtn{
background-color:#1AACE3 !important;
height: 52px;
border-radius: 9px !important;
color: white !important;
width: 150px;
font-size: 18px !important;
font-weight: 700 !important;
}

.adduserbtn{
font-size: 25px;
padding-left: 1rem;
 text-decoration-line:underline !important;
 padding-bottom: 10px;
}

