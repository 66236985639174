.swiper-sec {

    width: 100% !important; /* Ensure full width */

    
}


.swiperslide{

    width: 100% !important;

}


.swipercard {

    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    padding: 3rem 0rem;
    width: 100%; /* Ensure full width */

}

.swipercard h6 {
    padding-top: 4rem;
    font-size: 36px;
    font-weight: 600;
    padding-bottom: 0.5rem;
    width: 70%;

}

.swipercard p {

    font-size: 18px;
    font-weight: 400;
    width: 70%;

}


.swiper-pagination-bullet {
    background-color: #E54949 !important;
}

@media (max-width:480px) {

    .swipercard {

        display: flex;
        flex-direction: column-reverse;
        column-gap: 0rem;
        padding: 1rem 0rem;
        margin: 0px;
        width: 100% !important;
        justify-content: center;
        align-items: center;
        text-align: center;

    }

    .swiperslide{

        width: 100% !important;

    }

    

    .swiper-wrapper {
        width: 100%;
        padding: 0px;
        margin: 0px;
    }

    .swipercard h6 {
        padding-top: 2rem;
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 0.5rem;
        width: 100%;

    }

    

    .swipercard p {

        font-size: 12px;
        font-weight: 400;
        width: 100%;
        padding: 0px 0px;


    }



    .swiper-sec {

        width: 100% !important;

    }

    .img-slider{

        width: 100%; /* Adjust image width for better responsiveness */
        max-width: 300px; /* Limit the max width */    }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .swipercard {

        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 0rem;
        padding: 1rem 0rem;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;

    }

    .swipercard h6 {
        padding-top: 2rem;
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 0.5rem;
        width: 100%;

    }

    .swipercard p {

        font-size: 12px;
        font-weight: 400;
        width: 100%;
        padding: 0px 1rem;


    }

    .swipercard-right img {

        width: 80%;
    }

    .swiper-sec {

        width: 100% !important;

    }

}