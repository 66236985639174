.Modal {

    width: auto;
    height: auto;
    position: fixed;
    top: 40%;
    left: 45%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;


}

.btns{
    padding-top: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5%;
}

.Confirm{

    background-color: red;
    color: white;
    cursor: pointer;
    font-weight: bold;
    border: 0px;
    border-radius: 8px;
    height: 32px;
    width: 100%;

}

.cancel{

    background-color: white;
    color: black;
    cursor: pointer;
    font-weight: bold;
    border: 1px solid green;
    border-radius: 8px;
    height: 32px;

}

.title{
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 30px;
    font-weight: 700;
}



.table{
    padding-right: 10px !important;
    border-radius: 20px !important;
}
  
  .table th,
  .table td {
    padding:10px !important; /* Internal padding for cells */
    border-bottom: 7px solid #d7d7ce !important;
    margin: 1rem ;
    
  }

  .table{
    border-radius: 20px !important;

    
  }

  .table tr{
  }

  .readbtn{

    background-color: #6C6C6C8F !important;
    width: auto;
    margin-right: 2%;


    }

    .editbackground{
         color: white !important;
        font-weight: bold !important;
        width: 100%;
        height: 15px;
    }

    .editbtn{

        background-color: #1AACE3 !important;
        width: auto;
        margin-right: 2%;

    }

    .deletebtn{
        width: auto;

        background-color: #dc3545 !important;
    }

    .viewicon{
        color: white !important;
        width: 17px;
    }

    @media (max-width: 1450px) {


        .readbtn{

            background-color: #6C6C6C8F !important;
            width: 25px;
        
            }
        
            .editbackground{
                 color: white !important;
                font-weight: bold !important;
                width: 15px;
                height: 15px;
                margin-left: 5% !important;
              
            }
        
            .editbtn{
        
                background-color: #1AACE3 !important;
                width: 25px;
        
            }
        
            .deletebtn{
                width: 25px;
        
                background-color: #dc3545 !important;
            }
        
            .viewicon{
                color: white !important;
                width: 9px;
                height: 9px;

            }






    }
